<template>
  <BasicModal
    :show-action-button="false"
    :show-cancel-button="false"
  >
    <div class="stripe-checkout-return">

      <!-- CANCELLATIONS -->
      <template v-if="newStripeProduct.metadata.productSlug === 'free'">
        <CheckCircleIcon class="check-icon" />
        <h4>Downgrade scheduled</h4>
        <p>
          You still have Dispatch {{ oldStripeProduct.name }} perks
          <template v-if="isPPAEnabled">
            <br>
            and Pay per Action
          </template>
          through
          <br>
          <strong>{{ usageCycleEndsAt }}</strong>.
        </p>
        <p v-if="stripeCustomer && stripeCustomer.email">
          A confirmation has been emailed to
          <br>
          {{ stripeCustomer.email }}.
        </p>
        <div class="buttons">
          <button class="small" @click="close">Back to Dispatch</button>
        </div>
      </template>

      <!-- PLAN DOWNGRADES -->
      <template v-else-if="isDowngrade">
        <CheckCircleIcon class="check-icon" />
        <h4>Downgrade successful</h4>
        <p>
          You’re on the {{ newStripeProduct.name }} plan.
        </p>
        <template v-if="arePatchesPseudoPaused">
          <p class="text-danger-700">
            You’ve used more than your {{ formatNumber(userPlan.actionEventLimit) }} monthly Actions;
            <br>
            <strong>your Patches are paused.</strong>
          </p>
          <p class="text-danger-700">
            You will be able to turn on Patches after
            <strong>{{ usageCycleEndsAt }}.</strong>
          </p>
        </template>
        <p v-if="stripeCustomer && stripeCustomer.email">
          A confirmation has been emailed to
          <br>
          {{ stripeCustomer.email }}.
        </p>
        <div class="buttons">
          <button class="small" @click="close">Back to Dispatch</button>
        </div>
      </template>

      <!-- PLAN UPGRADES -->
      <template v-else>
        <img class="plan-image" :src="newStripeProduct.images ? newStripeProduct.images[0] : '@/assets/images/plans/satellite-dish.png'" />
          <h4>You're on the {{ newStripeProduct.name }} Plan</h4>
          <p v-if="stripeCustomer && stripeCustomer.email">
            A receipt has been emailed to {{ stripeCustomer.email }}.
          </p>
          <p v-if="newStripeProduct.metadata.productSlug === 'pro'">
            Congrats! You now get {{ formatMaxEventsMonthly(newStripeProduct) }} Actions a month, {{ formatMaxEventsTimeframe(newStripeProduct) }} of activity history, and priority support.
          </p>
          <p v-else>
            Congrats! You now get {{ formatMaxEventsMonthly(newStripeProduct) }} Actions a month, and access to {{ formatMaxEventsTimeframe(newStripeProduct) }} of activity history.
          </p>
          <div class="buttons">
            <button class="small" @click="close">Back to Dispatch</button>
          </div>
      </template>

    </div>
  </BasicModal>
</template>

<script>

  import { mapState, mapGetters } from 'vuex'

  import useFilters from '@/composables/useFilters'

  import BasicModal from '@/components/modals/_BasicModal.vue'

  import CheckCircleIcon from '@/assets/icons/check-circle.svg'

  export default {
    components: {
      BasicModal,
      CheckCircleIcon,
    },
    props: {
      newStripeProduct: {
        type: Object,
        required: true,
      },
      oldStripeProduct: {
        type: Object,
        required: true,
      },
    },
    setup() {

      const { formatNumber } = useFilters()
      const { formatMaxEventsMonthly, formatMaxEventsTimeframe } = useFilters()

      return {
        formatNumber,
        formatMaxEventsMonthly,
        formatMaxEventsTimeframe,
      }

    },
    computed: {
      ...mapGetters('user', ['isPPAEnabled', 'usageCycleEndsAt']),
      ...mapState('user', ['user', 'userPlan', 'arePatchesPseudoPaused']),
      isDowngrade() {
        // @NOTE: techincally the Number.parseInt() calls aren't necessary here
        //  since you can compare numbers as strings just fine, but this seems
        //  more appropriate
        return Number.parseInt(this.newStripeProduct.metadata.planTier, 10) < Number.parseInt(this.oldStripeProduct.metadata.planTier, 10)
      },
    },
    data() {
      return {
        stripeCustomer: null,
      }
    },
    created() {
      this.$store.state.api.dispatch.get(`/stripe/customers/${this.userPlan.stripeCustomerId}`)
        .then(((response) => {
          this.stripeCustomer = response.data
        }))
        .catch(() => {
          this.stripeCustomer = { email: this.user.email }
        })
    },
    methods: {
      close() {
        this.$store.dispatch('modals/CLOSE_MODAL')
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .stripe-checkout-return
    @apply flex
    @apply flex-col
    @apply text-center
    @apply items-center

    h4
      @apply my-4

    p + p
      @apply mt-4

    .plan-image
      @apply w-32
      @apply h-32

    .check-icon
      @apply w-16
      @apply h-16
      @apply text-success-500

    .buttons
      @apply mt-6
      @apply flex
      @apply gap-4

      svg
        @apply w-4
        @apply h-4
        @apply ml-2

</style>
